<template>
  <v-card>
    <PressNotesPgHeader></PressNotesPgHeader>
    <section class="form-full-width">
      <FormPressNotesPg :loading="loading" :getPressNotesPg="getPressNotesPg" @beforeUpdate="updatePressNotesPg"></FormPressNotesPg>
    </section>
  </v-card>
</template>

<script>
import FormPressNotesPg from '@/components/forms/FormPressNotesPg.vue'
import PressNotesPgHeader from './PressNotesPgHeader.vue'
import usePressNotesPg from '@/composables/usePressNotesPg'

export default {
  components: {
    FormPressNotesPg,
    PressNotesPgHeader,
  },
  setup() {
    // Composition API
    const {
      pressNotesPg,
      loading,
      // methods
      getPressNotesPg,
      updatePressNotesPg,
    } = usePressNotesPg()

    return {
      pressNotesPg,
      loading,
      // methods
      getPressNotesPg,
      updatePressNotesPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
