import {ref} from '@vue/composition-api';
import store from '@/store/index';

const usePressNotesPg = () => {
  // base data
  const pressNotesPg = ref (null);
  const loading = ref (false);

  // methods
  const getPressNotesPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchPressNotesPg');
    loading.value = false;
    if (status != 200) return;
    pressNotesPg.value = data;
    return data;
  };

  const updatePressNotesPg = async pressNotesPg => {
    loading.value = true;
    const {data, status} = await store.dispatch (
      'updatePressNotesPg',
      pressNotesPg
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    pressNotesPg,
    loading,
    // methods
    getPressNotesPg,
    updatePressNotesPg,
  };
};

export default usePressNotesPg;
